import { AnimatePresence, motion } from 'framer-motion';
import { Dispatch, SetStateAction, useState } from 'react';
import { RiExternalLinkLine } from 'react-icons/ri';
import { LinkType, LoginType } from '../../../../types';
import Icon, { Icons } from '../../../icons/Icon';
import NoPrefetchLink from '../../../NoPrefetchLink';

function TopLinks({
  links,
  isMenuOpen,
  logins,
  isLoginOpen,
  setIsLoginOpen,
  closeMenu,
}: {
  links: LinkType[];
  isMenuOpen: boolean;
  logins: LoginType[];
  isLoginOpen: boolean;
  setIsLoginOpen: Dispatch<SetStateAction<boolean>>;
  closeMenu: () => void;
}) {
  const [hoveredLink, setHoveredLink] = useState('');

  return (
    <ul className="z-10 flex items-center py-4 tracking-wide">
      {links.map((link) => {
        return (
          <li key={link._key}>
            <NoPrefetchLink
              href={link.href || '/'}
              onClick={() => closeMenu()}
              className={`${
                isMenuOpen ? 'text-background' : 'text-on-background'
              } group relative duration-700`}
            >
              <motion.div
                onHoverStart={() => {
                  setHoveredLink(link.linkText);
                  if (isLoginOpen) setIsLoginOpen(false);
                }}
                onHoverEnd={() => setHoveredLink('')}
                className="flex items-center gap-2 px-4 py-[7px] text-xl"
              >
                {link?.href?.includes('kampanjer') && <Icon icon={Icons.PERCENTAGE} />}
                {link?.href?.includes('forhandler') && <Icon icon={Icons.MAP_PIN} />}
                <span>{link.linkText}</span>
                {link.linkText === hoveredLink && <HoverPill isMenuOpen={isMenuOpen} />}
              </motion.div>
            </NoPrefetchLink>
          </li>
        );
      })}

      <li className="group relative">
        <motion.button
          onHoverStart={() => setHoveredLink('Logg inn')}
          onHoverEnd={() => {
            setHoveredLink('');
          }}
          onClick={() => {
            closeMenu();
            setIsLoginOpen((prev) => !prev);
          }}
          className={`${
            isMenuOpen ? 'text-background' : 'text-on-background'
          } flex items-center gap-2 px-4 py-[7px] text-xl duration-700`}
        >
          <Icon icon={Icons.PERSON} />
          <span>Logg inn</span>
          {hoveredLink === 'Logg inn' && <HoverPill isMenuOpen={isMenuOpen} />}
        </motion.button>

        <AnimatePresence>
          {isLoginOpen && (
            <motion.div
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 24 }}
              exit={{ opacity: 0, y: 0 }}
              onHoverEnd={() => setIsLoginOpen(false)}
              transition={{
                duration: 0.3,
                ease: 'easeInOut',
              }}
              className="relative"
            >
              <ul className="bg-background absolute right-1/2 z-50 flex w-80 translate-x-1/2 flex-col gap-1 rounded p-2 shadow-md">
                {logins.map((login) => (
                  <motion.li
                    key={login._id}
                    onHoverStart={() => setHoveredLink(login.title)}
                    className="relative cursor-pointer rounded-md bg-white/80 px-6 py-4 duration-300 hover:bg-[#ddd9d3] active:bg-[#ada9a5] "
                  >
                    <a
                      href={login.cta.url}
                      className="after:absolute after:inset-0 after:content-['']"
                    >
                      <span className="inline-block pr-4 text-lg font-medium">{login.title}</span>
                      <RiExternalLinkLine className="absolute right-2 top-2 opacity-40" />
                    </a>
                    <p>{login.description}</p>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </li>
    </ul>
  );
}

function HoverPill({ isMenuOpen }: { isMenuOpen: boolean }) {
  return (
    <motion.div
      layoutId="linkPill"
      transition={{
        layout: {
          duration: 0.4,
          type: 'spring',
        },
      }}
      className={`${
        isMenuOpen
          ? 'bg-secondary group-active:bg-[#0000B5]'
          : ' bg-[#ddd9d3] group-active:bg-[#ada9a5]'
      } absolute inset-0 -z-10 rounded-full
      `}
    />
  );
}

export default TopLinks;
